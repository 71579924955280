import { useEffect, useRef, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useFetcher } from "react-router";
import { IconBlank, IconCheck, IconDown, IconUp } from "~/components/icons";
import { ListGroupItem } from "~/components/list-group";
import MilestoneForm from "~/components/scoreboard/milestone-form";
import type { DashboardMetricFieldsFragment } from "~/types/api";

interface Props {
  sorting?: boolean;
  row: number;
  metric: DashboardMetricFieldsFragment;
  readOnly?: boolean;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}

export default function MilestoneItem({
  sorting,
  row,
  metric,
  canMoveUp,
  canMoveDown,
  readOnly
}: Props) {
  const fetcher = useFetcher<unknown>();
  const [explode, setExplode] = useState(false);
  const ref = useRef<number>(metric.data.value || 0);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (ref.current !== metric.data.value && metric.data.value) {
      ref.current = metric.data.value;
      setExplode(metric.data.value === 100);
    }
  }, [metric.data.value]);

  const moveUp = () =>
    fetcher.submit(
      { id: metric.id, position: "up" },
      { action: "/resources/metrics/save", method: "post" }
    );
  const moveDown = () =>
    fetcher.submit(
      { id: metric.id, position: "down" },
      { action: "/resources/metrics/save", method: "post" }
    );

  const percent = Math.min(metric.data.value || 0, 100);
  return (
    <ListGroupItem className="p-0">
      <div
        className={
          "pointer-events-none absolute bottom-0 left-0 top-0 bg-green-400 opacity-10"
        }
        style={{ width: `${percent}%` }}
      />
      {editing && (
        <MilestoneForm id={metric.id} onClose={() => setEditing(false)} />
      )}
      {explode && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={200}
            onComplete={() => setExplode(false)}
            duration={3000}
          />
        </div>
      )}
      <div className={"flex justify-between space-x-4 px-6 py-2"}>
        <div style={{ width: 15 }}>{row}.</div>
        <div className="flex-1">
          {readOnly ? (
            metric.data.description
          ) : (
            <a onClick={() => setEditing(true)}>{metric.data.description}</a>
          )}
        </div>
        <div className="flex justify-end text-center" style={{ width: 50 }}>
          {sorting ? (
            <span>
              {canMoveUp ? (
                <a onClick={moveUp}>
                  <IconUp fixed />
                </a>
              ) : (
                <IconBlank />
              )}
              {canMoveDown ? (
                <a onClick={moveDown}>
                  <IconDown fixed />
                </a>
              ) : (
                <IconBlank />
              )}
            </span>
          ) : (
            <span>
              {metric.data.value! >= 100 ? <IconCheck /> : <>{percent}%</>}
            </span>
          )}
        </div>
      </div>
      <div
        className="absolute bottom-0 left-0 h-1 bg-green-500 opacity-50"
        style={{
          width: `${percent}%`
        }}
      />
    </ListGroupItem>
  );
}
